import $ from 'jquery';

export default class SlickSlider {
  init() {
    // console.log('initsliders');
    $('[data-slick]')
      .not('.slick-initialized')
      .each(function () {
        let self = $(this);
        // if(self.data('count') > 0){
        //     return;
        // }

        self.slick({
          prevArrow:
            '<button type="button" class="slick-prev"><span class="visually-hidden">Précédent</span></button>',
          nextArrow:
            '<button type="button" class="slick-next"><span class="visually-hidden">Suivant</span></button>',
        });
      });
  }
}
